/* CacheBuster component */
import React from 'react';
import packageJson from './../../package.json';
import {LocalStorageEnum} from './enums';
global.appVersion = packageJson.version;

const IsNewVersion = (versionA, versionB) => {
    
    if(versionB === null) return true;
    // code from above snippet goes here
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
  
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
}

export default class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...')
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
        }
        // delete browser cache and hard reload
        this.forceReload();
      }
    };
  }
  forceReload() {
        const form = document.createElement('form');
        form.method = "GET";
        form.action = window.location.href;
        document.body.appendChild(form);
        form.submit();
}
  componentDidMount() {
        const currentVersion = sessionStorage.getItem(LocalStorageEnum.AppVersion);
        const latestVersion = global.appVersion;

        const shouldForceRefresh = IsNewVersion(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          sessionStorage.setItem(LocalStorageEnum.AppVersion, latestVersion);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}
