const initialState = {
        key: 0,
        className: '',
        show: false,
        title: '',
        message: '',
        boldText: '',
        onClickOK: null
};
export const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_ALERT':
            return {
                key: state.key + 1,
                className: action.payload.className,
                show: true,
                title: action.payload.title,
                message: action.payload.message,
                boldText: action.payload.boldText,
                onClickOK: action.payload.onClickOK,
            }
        default:
            return state;
    }
};