import { combineReducers } from 'redux';
import { loadingScreenReducer } from './reducers/loadingScreenReducer';
import { alertReducer } from './reducers/alertReducer';
import { commonReducer } from './reducers/commonReducer';
 
const storeReducer = combineReducers({
    loadingScreen: loadingScreenReducer,
    alert: alertReducer,
    common: commonReducer,
})

export default storeReducer;