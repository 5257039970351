import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from "react-router-dom";
import CacheBuster from './util/CacheBuster';

import './App.css';

// Containers
// const Batches = React.lazy(() => import("./components/Batches"));
// const ErrorPage = React.lazy(() => import("./components/Errors/404"));
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));



class App extends Component {
  render() {
    let myLoading = () => (
      <div className="animated fadeIn pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
      </div>
    );

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {  refreshCacheAndReload(); }
          return (
            <BrowserRouter>
              <React.Suspense fallback={myLoading()}>
                <Switch>
                  <Route
                    path="/"
                    name="DefaultLayout"
                    render={props => <DefaultLayout {...props} />}
                  />
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          );

        }
        }
      </CacheBuster>
    )
  }

}

export default App;
