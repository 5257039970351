
export const LocalStorageEnum = Object.freeze({
  Title: "Title",
  BreadcrumbList: "BreadcrumbList",
  StoreType: "StoreType",
  CurrentStock: "CurrentStock",
  LocationID: "LocationID",
  BatchID: "BatchID",
  BatchType: "BatchType",
  CategoryName: "CategoryName",
  CategoryCode: "CategoryCode",
  SubCategoryCode: "SubCategoryCode",
  CurrentUser: "currentUser",
  OrderNumber: "OrderNumber",
  CustomerID: "CustomerID",
  NoOfItems: "NoOfItems",
  DefaultLocation: 'defaultLocation',
  DefaultLocationDescription: 'DefaultLocationDescription',
  MainMenu: 'mainMenu',
  ClientIP: 'ClientIP',
  BatchStatusID: 'BatchStatusID',
  IsRejectedBatch: 'IsRejectedBatch',
  Behavior: 'Behavior',
  WebTotal: 'WebTotal',
  CurrentLat: "CurrentLat",
  CurrentLon: "CurrentLon",
  CurrentOutletCode: "CurrentOutletCode",
  CurrentOutletDescription: "CurrentOutletDescription",
  CurrentIPAddress: "CurrentIPAddress",
  inputItemCode: "inputItemCode",
  currentLocationCode: "currentLocationCode",
  currentLocationDescription: "currentLocationDescription",
  currentLocationLat: "currentLocationLat",
  currentLocationLon: "currentLocationLon",
  AppVersion: "AppVersion",
  IsPickAndGo: "IsPickAndGo",
});


export const SessionStorageEnum = Object.freeze({
  Exp_PercentageList: 'Exp_PercentageList',
  Exp_SessionID: 'Exp_SessionID',
  Exp_ExpiryID: 'Exp_ExpiryID',
  Exp_ItemBatchList: 'Exp_ItemBatchList',
  Exp_ItemCode: 'Exp_ItemCode',
  Exp_ItemDescription: 'Exp_ItemDescription',
  Exp_SubDepartment: 'Exp_SubDepartment',
  Exp_SelectedStore: 'Exp_SelectedStore',
  Exp_RemainExpireDays: 'Exp_RemainExpireDays',
  Exp_MaxDiscount: 'Exp_MaxDiscount',
  Exp_ExpiryDate: 'Exp_ExpiryDate',
  Exp_ReturnableOrNon: 'Exp_ReturnableOrNon',
  Exp_SelectedDepartment: 'Exp_SelectedDepartment',
  Low_Stock_Qty: 'Low_Stock_Qty',
  Exp_SelectedTile: 'Exp_SelectedTile'
});

export const DepartmentEnum = Object.freeze({
  Exp_DryProducts: 'DP',
  Exp_Liquor: 'L',
  Exp_SeaFoodAndMeat: 'SM',
  Exp_VegetablesAndFruits: 'VF',
  Exp_FreshFood: 'FF',
});

export const ExpiryItemTypesEnum = Object.freeze({
  Returnable: 'R',
  NonReturnable: 'N',
  NonReturnableRemove: 'NR',
})

export const LogoutType = Object.freeze({
  ByUser: 1,
  ByAdmin: 2,
  BySystem: 3
});

export const StockTypesEnum = Object.freeze({
  FrontEnd: 'F',
  BackEnd: 'B',
  OnlineOrders: 'O',
  Pending: 'P',
});
export const ModalButtonsEnum = Object.freeze({
  YesNo: 'YN',
  AcceptReject: 'AR',
  OK: 'OK',
  YesNoCancel: 'YNC',
  Custom: 'C'
});
export const DepartmentEmployeeListEnum = Object.freeze({
  SearchName: "SearchName"
});

export const OutletListEnum = Object.freeze({
  SearchName: "SearchName"
});

export const ProductInquiryEnum = Object.freeze({
  InputItemCode: "InputItemCode",

});

export const PositionEnum = Object.freeze({
  RetailManager: 2,
  RegionalManager: 3,
  FreshFoodClusterManager: 20,
  CustomerServiceDevelopmentManager: 21,
  FreshFoodSpecialist: 22,
  MaintenanceTechnisian: 23,
  MaintenanceExecutive: 24,
  FinanceExecutive: 25
});

export const ClusterEnum = Object.freeze({
  RetailManager: 2,
  RegionalManager: 3,
  FreshFoodClusterManager: 16,
  CustomerServiceDevelopmentManager: 17,
  FreshFoodSpecialist: 18,
  MaintenanceTechnisian: 19,
  MaintenanceExecutive: 20,
  FinanceExecutive: 21
});

export const ExpiryEnum = Object.freeze({
  InputItemCode: 'InputItemCode',
  StockQty: 'StockQty'
});

export const LowStockScanStatusEnum = Object.freeze({
  Scan: 1,
  Discard: 2,
  Complete: 3,
  Pick: 4
});

export const LowStockScanActionEnum = Object.freeze({
  Scan: 1,
  ScanAdd: 2,
  ScanOverwrite: 3,
  ScanEdit: 4,
  ScanDiscard: 5,
  Pick: 6,
  PickLater: 7,
  PickItemsNothing: 8,
  PickEdit: 9,
  PickDiscard: 10,
  NoMoreItemsToPick: 12,
});

export const CentralOutletCodeEnums = Object.freeze({
  SGNE: "SGNE",
  //S2HK: "S2HK",
})

export const ExpiryItemEnum = Object.freeze({
  FrontScan: 'F',
  BackScan: 'B',
  BothScan: 'A',
})

export const ReduxEnums = Object.freeze({
  CurrentOutlet: 'CurrentOutlet',
  UpdateBreadcrumb: 'UpdateBreadcrumb',
})

export const ExpiryActionsEnum = Object.freeze({
  Returnable_Front_Scan:3,
  Returnable_Back_Scan:4,
  None_Returnable_Front_Scan:5,
  Non_Returnable_Back_Scan:6,

  Non_Ret_Front_Remove:9,
  Non_Ret_Back_Remove:10,
  Non_Ret_fully_removed:11,

})

export const ExpiryStatusEnum = Object.freeze({
  Returnable_Front_Scan_Completed:2,
  Returnable_Back_Scan_Completed:3,
  None_Returnable_Front_Scan:5,
  Non_Returnable_Back_Scan:6,

  Non_ret_remove_front_scan: 13,
  Non_re_remove_back: 14,
  Non_re_completed: 15,
})

export const ItemStatusEnum = Object.freeze({
  Success: 1,
  InvalidItem: 2,
  AlreadyIn: 3,
  OngoingBatch: 4,
});

export const StatusEnum = Object.freeze({
  Recount_Items: 11,
  Recount_Item_ReScanned: 12,
});

export const SELBatchCreateEnum = Object.freeze({
  AutoName: 'A',
  ManualName: 'C'
});

export const EditItemEnum = Object.freeze({
  ItemCode: 'ItemCode',
  ItemName: 'ItemName',
  PerpStockID:0,
  IsEdit: false,
  Quantity: 0,
  Unit:'N'
});