import {ReduxEnums} from './../../util/enums';

const initialState = {
    currentOutletDetails: '',
    breadcrumbKey: 0
};
export const commonReducer = (state = initialState, action) => {
switch (action.type) {
    case ReduxEnums.CurrentOutlet:
        return {
            ...state,
            currentOutletDetails: action.payload
        };
    case ReduxEnums.UpdateBreadcrumb:
        return {
            ...state,
            breadcrumbKey: state.breadcrumbKey+1
        };
    default:
        return state;
}
};